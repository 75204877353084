
import { useEffect, useState } from 'react'
//import Image from 'next/image'
import { Flex, Image } from "@chakra-ui/react"
import { SITE_NAME } from '../utils/constants'
import getConfig from 'next/config'
import Loading from "./Loading"


const { publicRuntimeConfig } = getConfig()
const domains = publicRuntimeConfig.domains

const MEDIA_QUERY_SIZES = {
  base: "base",
  md: "md",
  lg: "lg"
}

const extractSize = (size, isWidth, mediaQuerySize, idData) => {
  var s
  try {
    s = size? (typeof size === "string" || typeof size === "number"? size : size[mediaQuerySize]) : null

  } catch(e) {
  }
  
  if(s) {
    s = `${s}`
    
    try {
      s = s.toLowerCase().replace("px", "").replace("rem", "").replace("rem", "")

    } catch (e) {
    }
    if(s.endsWith(isWidth? "vw" : "vh")) {
      switch (mediaQuerySize) {
        case MEDIA_QUERY_SIZES.base:
          s = 512
          break;
        case MEDIA_QUERY_SIZES.md:
          s = 768
          break;
        default:
          s = 1024
          break;
      }
    }
    
  }
  
  return s
}

const extractWidth = (width, mediaQuerySize, idData) => {
  return extractSize(width, true, mediaQuerySize, idData)
}
const extractHeight = (height, mediaQuerySize, idData) => {
  return extractSize(height, false, mediaQuerySize, idData)
}

const ImageView = ({ idData, d, display,
  cloudProvider, 
  src, 
  isDefaultHost, 
  host, 
  alt, title,
  fallbackProfilePhoto, 
  resWidth, resHeight,
  w, h, width, height, 
  intrisicWidth, intrisicHeight,
  ...props}) => {
  const imgSrc = imageSrc({
    src: src,
    width: extractWidth(intrisicWidth, "none"),
    height: extractHeight(intrisicHeight, "none")
  })
  const fallBack = !fallbackProfilePhoto? "" : imageSrc({
    src: fallbackProfilePhoto, isDefaultHost: true, host: host
  })

  const sizes = [
    {size: 512, mquery: MEDIA_QUERY_SIZES.base},
    {size: 768, mquery: MEDIA_QUERY_SIZES.md},
    {size: 1024, mquery: MEDIA_QUERY_SIZES.lg}
  ]
  const webpImages = []
  const defaultImages = []
  const webPData = {}
  const defaultData = {}
  var defaultImageExt = ""
  if(w || width || h || height) {
    for(const size of sizes) {
      const imageInfo = imageSrc({
        src: src, 
        width: extractWidth(resWidth || w || width, size.mquery, idData), 
        height: extractHeight(resHeight || h || height, size.mquery, idData), 
        suffix: ` ${size.size}w`,
        requiresSize: true, idData: idData
      })
      if(imageInfo.default) {
        defaultImages.push(imageInfo.default)
        defaultImageExt = imageInfo.ext
        defaultData[size.mquery] = imageInfo.default
      }
      if(imageInfo.webp) {
        webpImages.push(imageInfo.webp)
        webPData[size.mquery] = imageInfo.webp
      }
    }
  }

  const [loaded, setLoaded] = useState(true)
  const [lastSrc, setLastSrc] = useState()
  const [lastFallback, setLastFallback] = useState()

  return (
    <picture>
      {/*
        webpImages.length > 0?
        <source type="image/webp" 
        srcset={webpImages.join(", ")} />
        : null*/
      }
      {
        defaultImages.length > 0?
        <source type={`image/${defaultImageExt}`} 
        srcset={defaultImages.join(", ")} />
        : null
      }
      <Flex 
        display={loaded? "none" : "flex"} 
        position={loaded? "relative" : "absolute"}
        w={w || width || "50px"} 
        h={h || height || "50px"} justifyContent="center" alignItems="center"
      >
        <Loading type={Loading.TYPES.puff} width="25px" height="25px" color="#dfdfdf" />
      </Flex>
      <Image 
      data-src={lastFallback || webPData.toString()} 
      opacity={loaded? 1 : 0}
      alt={alt || title || SITE_NAME} /*
      fallbackSrc={fallbackProfilePhoto || defaultData}*/
      data-fallback-src={fallbackProfilePhoto || defaultData.toString()}
      w={w || width || "auto"} 
      h={h || height || "auto"} 
      htmlWidth={extractWidth(w || width, MEDIA_QUERY_SIZES.md)} 
      htmlHeight={extractHeight(h || height, MEDIA_QUERY_SIZES.md)} 
      onLoad={() => {
        setLoaded(true)
      }} 
      onError={() => {/*
        console.log("onImageError", "a", webPData.toString())
        if(!lastSrc) {
          setLastSrc(fallbackProfilePhoto || defaultData || "none")
          console.log("onImageError", "b", fallbackProfilePhoto || defaultData.toString())

        } else if(!lastFallback) {
          setLastFallback(src)
          console.log("onImageError", "c", src)
        }*/
      }}
      {...props} />
    </picture>
  )
}

export const imageSrc = ({src, width, height, suffix, requiresSize, idData}) => {
  var srcData = {default: src, ext: src && src.length? src.substring(src.lastIndexOf(".") + 1).toLowerCase() : ""}
  
  if(src && src.length > 0 && src.startsWith("/")) {
    if(requiresSize && isNaN(width) && isNaN(height)) {
      srcData = {}

    } else {
      var config = ""
      if(width && !isNaN(width)) {
        config += `w_${`${width}`.trim()}`
      }
      if(height && !isNaN(height)) {
        config += `${config.length > 0? "," : ""}h_${`${height}`.trim()}`
      }
      if(config.length > 0) {
        config = `/${config},q_auto`

      } else {
        config = `/q_auto`
      }
      const srcPrefix = `https://res.cloudinary.com/cosmobox-it/image/upload${config}`
      srcData.default = `${srcPrefix}${src}`
      srcData.webp = `${srcData.default.substring(0, srcData.default.lastIndexOf("."))}.webp`
      if(suffix) {
        srcData.default = `${srcData.default}${suffix}`
        srcData.webp = `${srcData.webp}${suffix}`
      }
      
    }
    
  }
  
  
  return srcData
}
/*
export const imageSrc = ({src, isDefaultHost, host}) => {
  return `${isDefaultHost && (src || '').startsWith("/")? DEFAULT_IMAGE_HOST : host && (src || '').startsWith("/")? host : ""}${(src || '')}`
}*/

export default ImageView
