import useTranslation from "next-translate/useTranslation"
import Link from "../../views/Link"
import { useRouter } from "next/router"

import { useState } from "react"
import LocaleSwitch from "./LocaleSwitch"
import Swal from "sweetalert2"

import { signOut, useSession } from 'next-auth/client'
import LoadingBalls from "../animations/LoadingBalls"
import getPageLinks from "../../hooks/page-links"
import { getFullUrl, userLink } from "../../utils/functions"
import { Alert, AlertIcon, Badge, Box, Button, Flex, HStack, Text, VStack } from "@chakra-ui/react"
import ImageView from "../../views/ImageView"
import { NO_PROFILE_PIC, PROFILE_PHOTO_SIZE_SM, THEME } from "../../utils/constants"
import { 
  FaBars, FaRegAddressBook, 
  FaSignInAlt, FaSignOutAlt, FaEnvelope, FaUpload
} from "react-icons/fa"
import Trans from "next-translate/Trans"


const { signInPageLink } = getPageLinks()

const $ = require('jquery')

const Logo = ({title, imageResWidth, imageResHeight, w, h, width, height, maxW, maxH, maxWidth, maxHeight, colorLight, colorDark, hideNameMobile, isRTL, ...props}) => {
  
  return (
      <HStack as={Link} href="/" textDecoration="none !important" textTransform="capitalize" 
      flexDirection={"row"} 
      marginInline={"0px !important"}
      {...props}>
          <ImageView alt={title || extractHeight} 
          src={`/logo-cosmobox.png`}
          w={w || width || "48px"} 
          h={h || height || "48px"} 
          marginInline={"0px !important"}
          mr={"0.5rem !important"} 
          ml={"0px !important"} 
          maxWidth={maxW || maxWidth || "100%"}
          maxHeight={maxH || maxHeight || "auto"} 
          resWidth={imageResWidth || w || width || "48px"} 
          resHeight={imageResHeight || h || height || "48px"} />
          <Box marginInline={"0px !important"} marginInlineStart="0px !important">
              <Text as="div" className="logo" fontSize={{base: "17px", md: "24px"}} fontWeight="800" 
              fontFamily="Kanit, sans-serif"
              color={"#fff"} 
              _hover={{
                  color: "#fff"
              }}
              display={!hideNameMobile? 'block' : {base: 'none', md: 'block'}} 
              marginInline={"0px !important"} marginInlineStart="0px !important">
                  {title}
              </Text>
          </Box>
      </HStack>
  )
}

const NavItem = ({as, children, href, ...props}) => {

  if(href) {
    return (
      <Link as={as || "a"} href={href} w={{base: "100%", md: "auto"}}
      p={{base: "15px", md: "15px"}} textTransform="capitalize" 
      display="flex" justifyContent="space-between" alignItems="center"
      {...props}>
        {children}
      </Link>
    )
  
  } else {
    return (
      <Text as={as || "div"} p={{base: "15px", md: "15px !important"}} textTransform="capitalize" 
      w={{base: "100%", md: "auto"}}
      display="flex" justifyContent="space-between" alignItems="center"
      {...props}>
        {children}
      </Text>
    )
  }
}

export default function NavBar({noSearchBar, forBanner, ...props}) {
    const { t, lang } = useTranslation('header')
    const [session, loading] = /*useState({
      user: {
        username: "test-username",
        id: "test-id",
        rank: 1,
        unreadMsg: 56
      }
    })//*/useSession()

    const router = useRouter()
    
    const { s } = router.query
    const [collapsed, setCollapsed] = useState()
    const [search, setSearch] = useState(s || "")

    const [hideStateLinks, setHideStateLinks] = useState(false)

    const [totalMessages, setTotalMessages] = useState(0)

    const hideVerificationAlert = () => {
      console.log("Nav::", router.pathname, getPageLinks().emailVerificationLink)
      return getPageLinks().emailVerificationLink.startsWith(router.pathname)
    }

    const handleChange = (e) => {
      setSearch(e.target.value)
    }
  
    const handleSearch = (e) => {
      e.preventDefault()
      if(search.length > 0) {
        closeNavbar();
        router.push(`/search/${search.trim()}`)
      }
    }
  
    const signOutWarning = e => {
      e.preventDefault()
      Swal.fire({
        text: t('sign-out-warning'), 
        icon: 'warning',
        confirmButtonText: t('common:yes'),
        cancelButtonText: t('common:cancel'),
        showCancelButton: true
      })
      .then(result => {
        if(result.isConfirmed) {
          signOut({redirect: signInPageLink})
        }
      })
  
    }
    const toggleNavbar = () => {
      setCollapsed(!collapsed)
    }
    const closeNavbar = () => {
      if (!collapsed) {
        toggleNavbar();
      }
    }

    var stateLinks;
    if(!session && loading) {
      stateLinks = <LoadingBalls />

    } else if(!session) {
      const callbackUrl = encodeURIComponent(window.location)
      //if the user is not logged in
      stateLinks = (
      <>
        <NavItem href={`/sign-in?callbackUrl=${callbackUrl}`}>
          {t('sign-in')}
          <Box display={{base: "block", md: "none"}}>
            <FaSignInAlt />
          </Box>
        </NavItem>
        <NavItem href={`/sign-up?callbackUrl=${callbackUrl}`}>
          {t('sign-up')}
          <Box display={{base: "block", md: "none"}}>
            <FaRegAddressBook />
          </Box>
        </NavItem>
        <Box className="nav-item dropdown">
          <LocaleSwitch />
        </Box>
        <NavItem h="40px" href={`/sign-in?callbackUrl=${callbackUrl}`} className="btn btn-primary navbar-btn text-cap" role="button">
          {t('sell')}
          <Box display={{base: "block", md: "none"}}>
            <FaUpload />
          </Box>
        </NavItem>
      </>
      )

    } else {
      stateLinks = (
      <>
        <HStack className="nav-item dropdown" alignItems="center">
          <Box as="a" className="nav-link dropdown-toggle" display="flex" alignItems="center" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <ImageView 
              alt="Cosmobox.it" 
              isDefaultHost 
              className="profile-image-round" 
              style={{
                display: "inline", 
                width: PROFILE_PHOTO_SIZE_SM, 
                height: PROFILE_PHOTO_SIZE_SM, 
                maxWidth: PROFILE_PHOTO_SIZE_SM, 
                maxHeight: PROFILE_PHOTO_SIZE_SM
              }} 
              width={PROFILE_PHOTO_SIZE_SM} 
              height={PROFILE_PHOTO_SIZE_SM} 
              src={session.user?.image || NO_PROFILE_PIC} />
          </Box>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <Link href={userLink(session.user.username, session.user.id)} className="dropdown-item text-cap">
              <div className="d-flex align-items-center">
                <i className="fa fa-user-circle margin-r-5"></i>
                <div>{t('profile')}</div>
              </div>
            </Link>
            <Link href={`${userLink(session.user.username, session.user.id)}?saves=1`} className="dropdown-item text-cap">
              <div className="d-flex align-items-center">
                <i className="fa fa-heart margin-r-5"></i>
                <div>{t('saved')}</div>
              </div>
            </Link>
            <Link href="/profile/settings" className="dropdown-item text-cap">
              <div className="d-flex align-items-center">
                <i className="fa fa-cog margin-r-5"></i>
                <div>{t('settings')}</div>
              </div>
            </Link>

            {
              session.user.rank > 0?
              <>
                <div className="dropdown-divider"></div>
                <Link href="/admin" className="dropdown-item text-cap">
                  <div className="d-flex align-items-center">
                    <i className="fa fa-dashboard margin-r-5"></i>
                    <div>{t('admin-panel')}</div>
                  </div>
                </Link>
              </> : null
            }
            
            <div className="dropdown-divider"></div>

            <Link href="/api/auth/signout" onClick={signOutWarning} className="dropdown-item text-cap">
              {t('sign-out')}
            </Link>
          </div>
        </HStack>
        <NavItem href="/profile/messages" pos="relative">
          <FaEnvelope />
          {
              session.user.unreadMsg > 0?
              <Badge variant="solid" colorScheme="green" borderRadius="15px" 
              pos="absolute" top="-3px" right="0">
                {`${session.user.unreadMsg} +`}
              </Badge>
              : null
          }
        </NavItem>
        <Box className="nav-item dropdown">
          <LocaleSwitch />
        </Box>
        <NavItem href="/sell" className="btn btn-primary navbar-btn text-upper" role="button" h="40px">
          {t('sell')}
          <Box display={{base: "block", md: "none"}}>
            <FaUpload />
          </Box>
        </NavItem>
      </>
      )
    }

    const height = "60px"
    const shadow = "0px 5px 21px -5px #0D1121"
    const bg = "#703fb9"
    const color = "#fff"

    const [showMenu, setShowMenu] = useState(false)
    
    return (
      <VStack
        position="sticky" zIndex="10" w="100%" top={{base: "0", md: 0}}>
        <HStack flexDirection="row" 
          justifyContent="space-between" alignItems="center" 
          w="100%" h={height}
          bgColor={bg} boxShadow={{base: "none", md: shadow}} color={color}
          px={{base: "16px"}} 
          py={"16px"} {...props}>
    
          <HStack justifyContent="flex-start" alignItems="center" 
            mr={{base: "3rem"}}
            flexDirection="row">
            <Logo title={t("site-name")}
            ml={{base: "0px !important"}} 
            mr={{base: "0px !important"}}
            w={{base: "40px"}}
            h="auto"
            imageResWidth={"80px"}
            maxWidth="40px !important" 
            intrisicWidth="80px" hideNameMobile={false} />
    
          </HStack>
    
          <Box display={{base: "flex", md: "none"}} justifyContent="center" alignItems="center" 
            onClick={() => {
              setShowMenu(!showMenu)
            }}>
            {
              !showMenu? <FaBars /> : <FaBars />
            }
          </Box>
          <Box
          justifyContent={{base: "flex-start", md: noSearchBar? "flex-end" : "space-between"}} 
          alignItems={{base: "flex-start", md: "center" }}
          flexDirection={{base: "column", md: "row"}} 
          marginInlineStart={"0px !important"} 
          display={{base: showMenu? "flex" : "none", md: "flex"}}
          position={{base: "absolute", md: "relative"}} 
          zIndex="5" bg={{base: bg, md: "transparent"}}
          w={{base: "100%", md: "100%"}} 
          p={{base: "15px", md: "0px"}}
          top={{base: height, md: "0px"}} left="0" right="0" 
          h={{base: "auto", md: "100%"}} 
          flexBasis="auto" flexFlow="1"
          className="flex-shrink-1 flex-grow-0">
            <Box w="100%" as="form" className={`mr-2 my-auto w-100 d-inline-block ${noSearchBar? "opacity-0" : ""}`} 
              onSubmit={handleSearch} onMouseEnter={() => setHideStateLinks(!noSearchBar)} 
              onMouseLeave={() => setHideStateLinks(false)}>
              <div className="input-group">
                <input
                  type="text"
                  value={search}
                  onChange={handleChange}
                  className="form-control border border-right-0"
                  placeholder={`${t('search-placeholder')}...`}
                />
                <span className="input-group-append">
                  <button aria-label="Search"
                    className="btn btn-outline-light border border-left-0"
                    type="submit"
                  >
                    <i className="fa fa-search" />
                  </button>
                </span>
              </div>
            </Box>
            <Box as="ul" w="100%" display="flex" flexDir={{base: "column", md: "row"}} 
            justifyContent={{base: "flex-start", md: "flex-end"}} 
            alignItems={{base: "flex-start", md: "center"}}
            className={`${hideStateLinks? 'd-md-none' : ''}`}>
            {
              stateLinks
            }
            </Box>
          </Box>
        </HStack>
        {
          forBanner || hideVerificationAlert() || !session?.user || session?.user?.emailVerified? null :
          <Box w="100%" mt="0px !important">
            <Alert w="100%" status="warning" p="15px">
              <AlertIcon />
              <HStack justifyContent="space-between" wrap="wrap" w="100%">
                <Box w={{base: "100%", md: "auto"}}>
                  {
                    session?.user?.emailVerificationSent?
                    <Trans i18nKey="header:alert-email-verification-sent" values={{email: session.user.email}} 
                    components={[<b />]} />
                    :
                    t("alert-verify-email")
                  }
                </Box>
                {
                    session?.user?.emailVerificationSent?
                    <HStack w={{base: "100%", md: "auto"}} py="15px">
                      <Button aria-label={t("request-verification-link")} as={Link} href={getPageLinks().emailVerificationLink} textTransform="capitalize" background="#DD206B" color="#fff" _hover={{opacity: "0.7", color: "#fff", textDecoration: "none"}}>
                        {t("request-verification-link")}
                      </Button>
                    </HStack>
                    :
                    <HStack w={{base: "100%", md: "auto"}} py="15px">
                      <Button aria-label={t("verify-email")} as={Link} href={getPageLinks().emailVerificationLink} textTransform="capitalize" background="#DD206B" color="#fff" _hover={{opacity: "0.7", color: "#fff", textDecoration: "none"}}>
                        {t("verify-email")}
                      </Button>
                    </HStack>
                }
              </HStack>
            </Alert>
          </Box>
        }
      </VStack>
    )
}
