import { userLink } from "../utils/functions";
import i18n from '../i18n';
import { FILTER_PATHS } from "../utils/constants";
var locales = i18n.locales,
    defaultLocale = i18n.defaultLocale;
export default function getPageLinks(arg) {
  return {
    signInPageLink: "".concat(arg !== null && arg !== void 0 && arg.locale && arg.locale != defaultLocale ? "/".concat(arg.locale) : "", "/sign-in"),
    signUpPageLink: "".concat(arg !== null && arg !== void 0 && arg.locale && arg.locale != defaultLocale ? "/".concat(arg.locale) : "", "/sign-up"),
    passwordResetLink: "".concat(arg !== null && arg !== void 0 && arg.locale && arg.locale != defaultLocale ? "/".concat(arg.locale) : "", "/token/pass-reset/?token=").concat(arg === null || arg === void 0 ? void 0 : arg.token),
    emailVerificationLink: arg !== null && arg !== void 0 && arg.token ? "".concat(arg !== null && arg !== void 0 && arg.locale && arg.locale != defaultLocale ? "/".concat(arg.locale) : "", "/token/email-verification?token=").concat(arg.token) : "".concat(arg !== null && arg !== void 0 && arg.locale && arg.locale != defaultLocale ? "/".concat(arg.locale) : "", "/token/email-verification?verify=1"),
    profilePageLink: userLink(arg === null || arg === void 0 ? void 0 : arg.username, arg === null || arg === void 0 ? void 0 : arg.id),
    //takes user object
    sellPageLink: "/sell",
    sellEditPageLink: "/sell/".concat(arg),
    //takes product id
    countryLink: "".concat(FILTER_PATHS.root, "/").concat((String(arg) || "#").toLowerCase()) //takes country sortname

  };
}